import Logo1 from "../img/earn-logo-1.png";
import Logo2 from "../img/earn-logo-2.png";
const Section2Content2 = () => {
    return (
        <>
        <div className='row mt-4'>
            <div className='col-5 text-end'>
                <img src={Logo1}></img>
            </div>
            <div className='col-2 text-center p-0'>
                <i className="bi bi-arrow-right-circle-fill" style={{ color: "var(--primary)", fontSize: "2rem" }}></i>
            </div>
            <div className='col-5 text-start'>
                <img src={Logo2}></img>
            </div>
        </div>
        </>
    )
}

export default Section2Content2;