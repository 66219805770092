import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../img/logo.png';
import Button from 'react-bootstrap/Button';

const NavigationBar = () => {
    return (
        <>
        <Navbar expand="lg" style={{ zIndex: 1, marginTop: "20px", justifyContent: "center"}}>
            <Container className='row'>
                <div className='col-lg-2 nav-1'>
                    <div className='logo'>
                        <img src={Logo} className='mb-1' style={{ height: "47.25px" }}/>
                        <Navbar.Brand href="/" className='spartan semi-bold app-name' style={{ color: "var(--tertiary)" }}>Rentify</Navbar.Brand>
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ms-auto navbar-order" style={{ backgroundColor: "var(--tertiary)" }} />
                </div>
                <div className='col-lg-6'>
                    <Navbar.Collapse id="responsive-navbar-nav" className='nav-2'>
                        <Nav className="nav-2 rubik medium" style={{ fontSize: "12.15px" }}>
                            <Nav.Link href="/" style={{ color: "var(--primary)" }}>Home</Nav.Link>
                            <Nav.Link href="/" style={{ color: "var(--tertiary)" }}>About</Nav.Link>
                            <Nav.Link href="/" style={{ color: "var(--tertiary)" }}>Token</Nav.Link>
                            <Nav.Link href="/" style={{ color: "var(--tertiary)" }}>Service</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
                <div className='col-lg-2'>
                    <Navbar.Collapse id="responsive-navbar-nav" className=' nav-3'>
                        <Button id='btn-header' style={{ fontSize: "12.15px" }}>dApps</Button>{' '}
                    </Navbar.Collapse>
                </div>
            </Container>
        </Navbar>
        </>
    )
}

export default NavigationBar;