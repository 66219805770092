import Card from 'react-bootstrap/Card';

const Section3 = () => {
    return (
        <>
        <div className="section-3 text-center pt-3 reveal">
            <div className='row mt-5 mb-5'>
                <h5 className='poppins semi-bold mt-5'>Easy Access</h5>
                <h1 className='poppins semi-bold mb-5'>Global GPU</h1>
            </div>
            <div class="phase">
                <div class="phase-body left">
                    <Card id='section-3-card'>
                        <Card.Body id="section-3-body">
                            <h1 className='title poppins regular'>Phase 1</h1>
                            <ul className='poppins regular'>
                                <li>Lorem</li>
                                <li>Lorem</li>
                                <li>Lorem</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </div>
                <div class="phase-body right">
                    <Card id='section-3-card'>
                        <Card.Body id="section-3-body" className='text-start'>
                            <h1 className='title poppins regular'>Phase 2</h1>
                            <ul className='poppins regular'>
                                <li>Lorem</li>
                                <li>Lorem</li>
                                <li>Lorem</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </div>
                <div class="phase-body left">
                    <Card id='section-3-card'>
                        <Card.Body id="section-3-body">
                            <h1 className='title poppins regular'>Phase 3</h1>
                            <ul className='poppins regular'>
                                <li>Lorem</li>
                                <li>Lorem</li>
                                <li>Lorem</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </div>
                <div class="phase-body right" style={{ marginBottom: "15rem" }}>
                    <Card id='section-3-card'>
                        <Card.Body id="section-3-body" className='text-start'>
                            <h1 className='title poppins regular'>Phase 4</h1>
                            <ul className='poppins regular'>
                                <li>Lorem</li>
                                <li>Lorem</li>
                                <li>Lorem</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>

        </>
    );
}

export default Section3;