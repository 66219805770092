import Card from 'react-bootstrap/Card';
import Amd from '../img/amd.png';
import Nvidia from '../img/nvidia.png';

const Section1Card1 = () => {
    return (
    <>
    <Card id='infrastructure-card'>
      <Card.Body id="infrastructure-body">
        <div className='providers row justify-content-center'>
            <div className='provider col-sm-6'>
                <h5 className='poppins light'>Infrastructure Providers</h5>
                <img src={Amd}></img>
                <img src={Nvidia}></img>
            </div>
            <div className='col-sm-2'>
                <h5 className='poppins light'>Status</h5>
                <h5 className='poppins light' style={{ color: "var(--primary)", fontSize: "18px" }}>Online</h5>
            </div>
            <div className='col-sm-2'>
                <h5 className='poppins light'>Active Node</h5>
                <h5 className='poppins light' style={{ color: "var(--primary)", fontSize: "20px" }}>32</h5>
            </div>
            <div className='col-sm-2'>
                <h5 className='poppins light'>Total GPUs</h5>
                <h5 className='poppins light' style={{ color: "var(--primary)", fontSize: "20px" }}>100</h5>
            </div>
        </div>
      </Card.Body>
    </Card>
    </>
    )
}

export default Section1Card1;