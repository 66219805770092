import NavigationBar from "../components/NavigationBar";
import FooterBar from "../components/FooterBar";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import React, { useEffect } from 'react';
import reveal from '../components/Reveal';

const Home = () => {
    useEffect(() => {
        window.addEventListener("scroll", reveal);
        return () => {
          window.removeEventListener("scroll", reveal);
        };
      }, []); 
    return (
        <>
        <NavigationBar/>
        <div className="container">
            <Section1/>
            <Section2/>
            <Section3/>
        </div>
        <FooterBar/>
        </>
    )
}

export default Home;