import Section2Card1 from '../../components/Section2Card1';
import Section2Card2 from '../../components/Section2Card2';
import Section2Content1 from '../../components/Section2Content1';
import Section2Content2 from '../../components/Section2Content2';
import Background from '../../img/bg-section-2.png';
import Image from '../../img/section2-image.png';

const Section2 = () => {
    return (
        <>
        <div className="section-2 text-center pt-3 reveal">
            <div className='row mt-5 mb-5'>
                <h5 className='poppins semi-bold mt-5'>Easy Access</h5>
                <h1 className='poppins semi-bold'>Global GPU</h1>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className='col-md-4'>
                    <Section2Card1 title="Setup Node" small="Setup and connect GPU node to server" content={<Section2Content1/>}/>
                </div>
                <div className='col-md-4' style={{ position: "relative" }}>
                    <Section2Card1 title="Connect to dApps" small="Setup and connect GPU node to server" content={<img src={Image} style={{ position: "absolute", bottom: "0", right: "10%", height: "9rem" }}></img>}/>
                </div>
                <div className='col-md-4'>
                    <Section2Card1 title="Earn Eth Mining" small="Recieve Instant payment ETH Mining from node" content={<Section2Content2/>}/>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <Section2Card2/>
            </div>
            <img src={Background} className="section-2-background" alt="Background"></img>
        </div>

        </>
    );
}

export default Section2;