import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../img/logo.png';
import ContactIcon from './ContactIcon';

const FooterBar = () => {
    return (
      <>
      <div className="footer mt-5">
            <div className='row footer-bar mt-3 justify-content-center'>
              <div className=' mb-4 col-lg-4 footer-column-1'>
                <div className='logo'>
                  <img src={Logo} className='mb-1' style={{ height: "47.25px" }}/>
                  <Navbar.Brand href="/" className='spartan semi-bold footer-name'>Rentify</Navbar.Brand>
                </div>
                <small className='rubik regular footer-small'>Cryptocurrencies. Tracker shows</small><br/>
                <small className='rubik regular footer-small'>the most accurate crypto live.</small>
                <ContactIcon/>
              </div>
              <div className=' mb-4 col-md-2 footer-column-2'>
                <h6 className='poppins regular mb-4'>Cryptocurrency</h6>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                    Calculate
                  </a>
                </p>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  Benifits
                  </a>
                </p>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  Pricing
                  </a>
                </p>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  Security
                  </a>
                </p>
              </div>
              <div className=' mb-4 col-md-2 footer-column-3'>
                <h6 className='poppins regular mb-4'>Service</h6>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  Card Information
                  </a>
                </p>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  Easy Transaction
                  </a>
                </p>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  Money Analysis
                  </a>
                </p>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  System & Condition
                  </a>
                </p>
              </div>
              <div className=' mb-4 col-md-2 footer-column-4'>
                <h6 className='poppins regular mb-4'>Roadmap</h6>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  FAQ
                  </a>
                </p>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                  Contact Us
                  </a>
                </p>
                <p className='p-footer'>
                  <a href='#' className='list rubik regular'>
                    News
                  </a>
                </p>
              </div>
            </div>

          <div className='text-center p-4 rubik regular' style={{ fontSize: "10px", color: "var(--tertiary)" }}>
          Copyright © 2024 Crypto, All rights reserved. Present by Rentify.
          </div>
      </div>
      </>
    );
}

export default FooterBar;