import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';

const Section2Content1 = () => {
    return (
        <>
        <Card id='progress-card' className='mt-4'>
            <Card.Body id="progress-body" className='text-start'>
                <div className='row'>
                    <div className='col-6'>
                        <h5 className='title poppins semi-bold mb-1'>EU</h5>
                    </div>
                    <div className='col-6 text-end'>
                        <h5 className='title poppins semi-bold mb-1'>34%</h5>
                    </div>
                </div>
                <ProgressBar className='progress-bar-section' variant="PRIMARY" now={34} animated/>
            </Card.Body>
        </Card>
        <Card id='progress-card'>
            <Card.Body id="progress-body" className='text-start'>
                <div className='row'>
                    <div className='col-6'>
                        <h5 className='title poppins semi-bold mb-1'>US</h5>
                    </div>
                    <div className='col-6 text-end'>
                        <h5 className='title poppins semi-bold mb-1'>74%</h5>
                    </div>
                </div>
                <ProgressBar className='progress-bar-section' variant="PRIMARY" now={74} animated/>
            </Card.Body>
        </Card>
        </>
    )
}

export default Section2Content1;