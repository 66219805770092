const ContactIcon = () => {
    return (
        <div className="contact-content-con">
            <div className="contact-icon">
                <a href="/" target="_blank" className='telegram-link'>
                    <div className='logo-icon telegram'></div>
                </a>
                <a href="/" target="_blank" className='discord-link'>
                    <div className='logo-icon discord'></div>
                </a>
                <a href="/" target="_blank" className='medium-link'>
                    <div className='logo-icon medium'></div>
                </a>
                <a href="/" target="_blank" className='coinmarketcap-link'>
                    <div className='logo-icon coinmarketcap'></div>
                </a>
            </div>
        </div>
    )
}

export default ContactIcon;