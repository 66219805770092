import Card from 'react-bootstrap/Card';

const Section2Card1 = (props) => {
    return (
    <>
    <Card id='section-2-card'>
      <Card.Body id="section-2-body" className='text-start'>
        <h5 className='title poppins semi-bold'>{props.title}</h5>
        <small className='poppins regular'>{props.small}</small><br/>
        {props.content}
      </Card.Body>
    </Card>
    </>
    )
}

export default Section2Card1;