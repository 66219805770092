import Button from 'react-bootstrap/Button';
import Section1Card1 from '../../components/Section1Card1';
import Coin1 from '../../img/coin1.png';
import Coin2 from '../../img/coin2.png';
import Coin3 from '../../img/coin3.png';
import Bitget from '../../img/logo-bitget.png';
import Dex from '../../img/logo-dex.png';
import Uni from '../../img/logo-uni.png';

const Section1 = () => {
    return (
        <>
        <div className="section-1 text-center pt-3">
            <img src={Coin1} className='coin coin-1'></img>
            <img src={Coin2} className='coin coin-2'></img>
            <img src={Coin3} className='coin coin-3'></img>
            <div className='row mt-5'>
                <h1 className='poppins bold hero mt-5'>Mine Smarter<br/><span style={{ color: "var(--primary)" }}>Earn Faster</span></h1>
                <small className='rubik regular' style={{ fontSize: "7.43px", color: "var(--tertiary)" }}>Discover the Wonder of Rentify AI. Your Gateway<br/>to GPU Lending</small>
            </div>
            <div className='row mt-4 justify-content-center'>
                <div className='col-6 text-end'>
                    <Button id='btn-header' style={{ fontSize: "12.15px" }}>Get Started</Button>{' '}
                </div>
                <div className='col-6 text-start'>
                    <Button id='btn-header-1' style={{ fontSize: "12.15px" }}>Learn More</Button>{' '}
                </div>
            </div>
            <div className='row justify-content-center infrastructure-row'>
                <Section1Card1/>
            </div>
            <div className="row">
                <div className="col-lg-4 mb-5">
                    <img src={Dex} style={{ maxWidth: "13rem" }}></img>
                </div>
                <div className="col-lg-4 mb-5">
                    <img src={Uni} style={{ maxWidth: "13rem" }}></img>
                </div>
                <div className="col-lg-4 mb-5">
                    <img src={Bitget} style={{ maxWidth: "13rem" }}></img>
                </div>
            </div>
        </div>
        </>
    );
}

export default Section1;