import Card from 'react-bootstrap/Card';
import Gpu from '../img/gpu.png';
import Logo1 from '../img/section2-logo1.png';
import Logo2 from '../img/section2-logo2.png';
import Logo3 from '../img/section2-logo3.png';

const Section2Card2 = () => {
    return (
    <>
    <Card id='section-2-card-2'>
      <Card.Body id="section-2-body" className='text-center sec2-contents'>
        <div className='row mb-5'>
            <h5 className='poppins semi-bold mt-5' style={{ fontSize: "14.85px" }}>How it Works</h5>
            <h1 className='poppins semi-bold' style={{ fontSize: "29.7px" }}>Our Best Service</h1>
            <small className='rubik regular' style={{ fontSize: "12px" }}>Lorem ipsum</small>
        </div>
        <div className='row'>
            <div className='col-lg-6 pb-5'>
                <img src={Gpu} className='gpu-img'></img>
            </div>
            <div className='col-lg-6 pb-5'>
                <div className='row mb-4 details'>
                    <div className='col-6 text-end'>
                        <img src={Logo1} style={{ maxWidth: "81px" }}></img>
                    </div>
                    <div className='col-6 text-start'>
                        <h1 className='poppins regular' style={{ fontSize: "14.85px" }}>Secure Storage</h1>
                        <small className='rubik regular' style={{ fontSize: "10.8px" }}>Lorem ipsum</small>
                    </div>
                </div>
                <div className='row mb-4 details'>
                    <div className='col-6 text-end'>
                        <img src={Logo2} style={{ maxWidth: "81px" }}></img>
                    </div>
                    <div className='col-6 text-start'>
                        <h1 className='poppins regular' style={{ fontSize: "14.85px" }}>Low Cost</h1>
                        <small className='rubik regular' style={{ fontSize: "10.8px" }}>Lorem ipsum</small>
                    </div>
                </div>
                <div className='row mb-4 details'>
                    <div className='col-6 text-end'>
                        <img src={Logo3} style={{ maxWidth: "81px" }}></img>
                    </div>
                    <div className='col-6 text-start'>
                        <h1 className='poppins regular' style={{ fontSize: "14.85px" }}>Protected by Insurance</h1>
                        <small className='rubik regular' style={{ fontSize: "10.8px" }}>Lorem ipsum</small>
                    </div>
                </div>
            </div>
        </div>

        
      </Card.Body>
    </Card>
    </>
    )
}

export default Section2Card2;